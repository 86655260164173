@import '~@angular/cdk/overlay-prebuilt.css';

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}

.map {
  position: relative !important;
  width: 100%;
  height: 100%;
  margin: 2px;
  padding: 2px;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
